export default class Template {

    static ctaCss = () => {
        return "float: right; background-color: #12B5EA; color: white; text-align: center; margin: auto; padding: 12px 5px; min-width: 150px; font-size: 15px; font-weight: 700; text-decoration: none;";
    };

    static fancyBar = (listing) => {
        return `
            <div style="position: fixed; top: 0; right: 0; left: 0; z-index: 100001; margin: 0 auto; background-color: #4b3900;">
                <div onClick="document.getElementById('fancyBar').style.display = 'none';" style="cursor: pointer; float: right; color: white; position: relative; top: -14px; right: 4px; margin: 5px;">x</div>
                <a href="${listing.link}?utm_source=blog&utm_medium=smart_content&utm_content=fancyBar" target="_blank" style="display: flex; box-sizing: border-box; padding: 10px; text-align: left; text-decoration: none; justify-content: space-between;">
                    <span style="display: flex; align-items: center;">
                        <span style="margin-right: 20px">
                            <img width="50" height="50" src="${listing.data.images[0].tile_url}"/>
                        </span>
                        <span style="font-weight: bold; color: white; font-family: "Whitney A","Whitney B",sans-serif;">
                            ${listing.title}
                        </span>
                    </span>
                    <span class="fancybar-right">
                        <div style="${Template.ctaCss()}">
                            View Listing
                        </div>
                    </span>
                </a>
            </div>
        `;
    };

    static grid = (title = null) => {
        return `
            <div class="hl-web-component mb-20">
                ${title ? `
                    <h2 class="top-promo wide" style="margin: 0px; padding: 0px; border: none;">
                        ${title} <small style="font-weight: normal;">on HawaiiLife.com</small>
                    </h2>
                ` : ''}
                <div id="smart-content-listing-grid" class="blog-post-collection"></div>
            </div>
        `;
    };

    static listingBorder = (columns) => {
        return `width: calc(${Math.round(100/columns)}%); box-shadow: 0px 6px 6px -2px rgb(0 0 0 / 24%); padding: 15px; box-sizing: border-box; min-width: 330px;`;
    };

    static listing = (listing, small) => {
        const link = (listing.status === 'active' ? `${listing.link}?` : `https://www.hawaiilife.com/listings/${listing.data.area.slug}/${listing.data.sub_area.slug}?displayType=grid&`) + 'utm_source=blog&utm_medium=smart_content&utm_content=cta';
        return `
            <a href="${link}" target="_blank" style="text-decoration: none; color: inherit">
                <div class="listing__details">
                    ${listing.status === 'active' ? `
                        <img src="${listing.data.images[0].url}" style="object-fit: cover; margin-bottom: 20px; ${small ? 'height: 220px;' : ''}"/>
                        ${small ? '' : `
                            <div style="${Template.ctaCss()}">
                                View Listing
                            </div>
                        `}
                        ${Template.listingInfo(listing, small)}
                    ` : `
                        ${Template.placeHolderCta('Sorry, this listing is no longer available!', `View ${listing.data.property_type_name} Listings in ${listing.data.sub_area.name}, ${listing.data.area.name}`, small)}
                        ${small ? Template.listingInfo(listing, true): ''}
                    `
                    }
                </div>
            </a>
        `;
    };

    static island = (island, small) => {
        const link = `https://www.hawaiilife.com/listings/${island.slug}?displayType=grid&utm_source=blog&utm_medium=smart_content&utm_content=cta`;
        return `
            <p>
                <a href="${link}" target="_blank" style="text-decoration: none; color: inherit">
                    <div class="listing__details">
                        ${Template.placeHolderCta(`View Listings on ${island.name === 'Big Island' ? 'the Big Island' : island.name}`, "View on HawaiiLife.com", small)}
                    </div>
                </a>
            </p>
        `;
    };

    static placeHolderCta = (message, ctaText, small) => {
        return `
            <div style="width: 100%; margin-bottom: 20px; ${small ? 'height: 220px;' : 'height: 400px;'} background-size: cover; background-image: url(https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/WebSocial.png);">
                <div style="position: relative; margin: auto; padding: 10px; ${small ? 'height: 120px; font-size: 16px; top: 25%;' : 'height: 145px; font-size: 33px; top: 30%; width: 70%;'} background: rgba(255,255,255,0.7); font-weight: bold; text-align: center;">
                    ${message}
                    <div style="${Template.ctaCss()}; float: none; width: fit-content; ${small ? 'margin-top: 10px;' : 'margin-top: 20px;'}">
                        ${ctaText}
                    </div>
                </div>
            </div>
        `;
    };

    static listingInfo = (listing, small) => {
        return `
            <div class="listing__info">
                <p style="font-size: ${small ? '17px' : '22px'}; margin: 0px 0px 15px;">
                    ${listing.title}
                </p>
            </div>
            <ul class="listing__info listing__info--strong">
                <li>$${new Intl.NumberFormat('en-US', { style: 'decimal', currency: 'USD' }).format(listing.data.asking_price)}</li>
                ${listing.data.total_beds ? `<li>${listing.data.total_beds} <span>bd</span></li>` : ''}
                ${listing.data.total_baths ? `<li>${listing.data.total_baths} <span>ba</span></li>` : ''}
                ${listing.data.total_floor_area ? `<li>${listing.data.total_floor_area} <span>sf</span></li>` : ''}
            </ul>
            <ul class="listing__info small">
                <li>${listing.data.dwelling_type_name}</li>
                ${listing.status === 'active' ? `
                    <li>${listing.data.land_ownership}</li>
                    <li>${listing.data.days_on_market} ${parseInt(listing.data.days_on_market) > 1 ? 'days' : 'day'}
                ` : ''}
            </ul>
        `;
    };

    // static stringify = (content) => JSON.stringify(content).replaceAll(',', ';').replaceAll('"','').slice(1,-1); 

}