import axios from 'axios';
import Promise from 'promise';
import Template from './template';

const ISLANDS = ['Oahu', 'Kauai', 'Big Island', 'Maui', 'Lanai', 'Molokai'];

class HLSmartContent {

    /** 
     * JSON array of listings. 
     * Each listing on this format:
     * ```
     * {
     *  advisor: "joe",
     *  link: "https://www.hawaiilife.com/joe/listings/foo-bar",
     *  status: null,
     *  slug: "foo-bar",
     *  data: {
     *      sold_price: null,
     *  }
     * }
     * ```
     */ 
    listings = [];

    islands = [];

    fancyBarInjected = false;

    static init = () => {
        const hl = new HLSmartContent();
        hl.log('starting...');
        hl.parseListings();
        if (hl.listings.length > 0) {
            hl.fetchListingsData().then(() => {
                hl.injectCtas();
                hl.fillBlankImagesAlt(hl.listings[0]);
            });
        } else {
            hl.parseIslands();
            hl.injectIslandCta();
        }
    };

    parseListings = () => {
        const regexp = /^(https:\/\/www.hawaiilife.com(\/\w+)?\/(listings|mls)\/([\w|-]+))/m;
        const links = Array.from(document.querySelectorAll('.blog-post a')).filter(a => regexp.test(a.href));
        const islands = ISLANDS.map(i => i.toLowerCase().replace(' ', ''));
        this.listings = links.reduce((accumulator, link) => {
            const match = regexp.exec(link.href);
            // Resetting regexp to avoid false nulls
            regexp.lastIndex = 0;
            const slug = match[4];
            if (!islands.includes(slug)) {
                accumulator.push({
                    "link": match[1],
                    "advisor": match[2] ? match[2].slice(1) : null,
                    "path": match[3],
                    slug,
                });    
            }
            return accumulator;
        }, []);
        this.log('Parsed Listings', this.listings);
    };

    parseIslands = () => {
        const links = Array.from(document.querySelectorAll('.article-footer a')).filter(a => ISLANDS.includes(a.innerText));
        this.islands = links.map(l => {
            return {
                "name": l.innerText,
                "slug": l.pathname.split('/').slice(-2, -1).join()
            };
        });
        console.log(this.islands);
    };

    fetchListingsData = () => {
        return new Promise.all(this.listings.map(l => this.fetch(l)));
    };

    fetch = async (listing) => {
        try {
            const { data } = await axios.get(`https://www.hawaiilife.com/api/v1/${listing.path}/${listing.slug}`);
            console.groupCollapsed(`${this.name()} ${listing.slug}`);
            console.log('Data fetched:', data);
            listing.data = data;
            listing.status = data.sold_price ? 'sold' : 'active';
            listing.title = `${data.address.line_1}${data.address.line_2 ? `, ${data.address.line_2}` : ''}`;
            console.groupEnd();
        } catch (error) {
            return console.error(error);
        }
    };

    injectCtas = () => {
        // Inserting the parent grid
        const articleFooter = document.querySelector('.article-footer');
        if (articleFooter && this.listings.find(l => typeof l.data !== "undefined")) {
            articleFooter.insertAdjacentHTML('beforebegin', Template.grid(`View ${this.listings.length > 1 ? 'these Listings' : 'this Listing'}`));
            this.listings.forEach((listing) => {
                this.injectArticleCta(listing);
                // this.injectFancyBar(listing);
            });
        }
    };

    injectArticleCta = (listing) => {
        const grid = document.querySelector('#smart-content-listing-grid');
        const small = this.listings.length > 1;
        if (grid) {
            var elem = document.createElement('div');
            const columns = this.listings.length > 2 ? 2 : this.listings.length;
            elem.className = 'listing';
            elem.style.cssText = Template.listingBorder(columns);
            elem.innerHTML = Template.listing(listing, small);
            const lastGrid = Array.from(document.querySelectorAll("[id='smart-content-listing-grid']")).pop();
            lastGrid.appendChild(elem);
        }
    };

    injectIslandCta = () => {
        // Inserting the parent grid
        const articleFooter = document.querySelector('.article-footer');
        if (articleFooter) {
            articleFooter.insertAdjacentHTML('beforebegin', Template.grid(null));
            const grid = document.querySelector('#smart-content-listing-grid');
            if (grid && this.islands.length > 0) {
                var elem = document.createElement('div');
                elem.innerHTML = Template.island(this.islands[0], false);
                elem.className = 'listing';
                elem.style.cssText = Template.listingBorder(1);
                grid.appendChild(elem);
            }
        }
    };

    injectFancyBar = (listing) => {
        if (!this.fancyBarInjected && listing.status === 'active') {
            console.log("Injecting FancyBar");
            var elem = document.createElement('div');
            elem.id = 'fancyBar';
            elem.innerHTML = Template.fancyBar(listing);
            document.body.appendChild(elem);    
            this.fancyBarInjected = true;
        }
    };

    fillBlankImagesAlt = (listing) => {
        const images = Array.from(document.querySelectorAll('.blog-post img')).filter(a => a.alt === '');
        images.forEach((image) => {
            if (listing.title) {
                image.alt = listing.title;
            }
        });
    };

    log = (msg, data = null) => {
        const classname = this.name();
        if (data) {
            console.log(`${classname} ${msg}:`, data);
        } else {
            console.log(`${classname} ${msg}`);
        }
    };

    name = () => this.constructor.name;
}

if (document.location.search.slice(1).split('&').find(s => s.includes('hl_smart='))) {
    HLSmartContent.init();
}
